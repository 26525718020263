import axios from 'lib/fetch/axios';

import getSelectedIdClinic from 'lib/helpers/getSelectedIdClinic';

function list() {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_AUTH_URL2}/clinics/${getSelectedIdClinic()}/patient-pagers`,
  });
}

function create(data: any) {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_AUTH_URL2}/clinics/${getSelectedIdClinic()}/patient-pagers`,
    data,
  });
}

function trigger({
  patientPagerIds,
  message,
  patientName,
  poliName,
  poliId,
  patientQueueNumber,
}: {
  patientPagerIds: string[];
  message: string;
  patientName: string;
  poliName: string;
  poliId: string;
  patientQueueNumber: string;
}) {
  return axios({
    method: 'post',
    url: `${
      process.env.REACT_APP_AUTH_URL2
    }/clinics/${getSelectedIdClinic()}/patient-pagers/trigger-page`,
    data: { patientPagerIds, message, patientName, poliName, poliId, patientQueueNumber },
  });
}

function details(id: string) {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_AUTH_URL2}/clinics/${getSelectedIdClinic()}/patient-pagers/${id}`,
  });
}

function update(id: string, data: any) {
  return axios({
    method: 'put',
    url: `${process.env.REACT_APP_AUTH_URL2}/clinics/${getSelectedIdClinic()}/patient-pagers/${id}`,
    data,
  });
}

function trash(id: string) {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_AUTH_URL2}/clinics/${getSelectedIdClinic()}/patient-pagers/${id}`,
  });
}

const exported = {
  list,
  create,
  details,
  trash,
  update,
  trigger,
};

export default exported;
