export function FallbackTable() {
  return (
    <div className="mt-2 flex flex-col gap-y-2">
      <div className="mx-4 md:mx-6 flex justify-between">
        <div className="w-16">
          <span className="inline-flex h-4 w-10 rounded bg-gray-200 dark:bg-gray-700"></span>
        </div>
        <div className="flex w-96 gap-x-4">
          <span className="inline-flex h-4 w-36 rounded bg-gray-200 dark:bg-gray-700"></span>
          <span className="inline-flex h-4 w-20 rounded bg-gray-200 dark:bg-gray-700"></span>
        </div>
        <div className="w-40">
          <span className="inline-flex h-4 w-28 rounded bg-gray-200 dark:bg-gray-700"></span>
        </div>
        <div className="w-40">
          <span className="inline-flex h-4 w-20 rounded bg-gray-200 dark:bg-gray-700"></span>
        </div>
      </div>

      <hr />

      {Array(6)
        .fill("fallback")
        .map((item, id) => {
          return (
            <div
              className="mx-4 md:mx-6 flex justify-between"
              key={`${item}-${id}`}
            >
              <div className="w-16">
                <span className="inline-flex h-4 w-10 rounded bg-gray-200 dark:bg-gray-700"></span>
              </div>
              <div className="flex w-96 gap-x-4">
                <span className="inline-flex h-4 w-36 rounded bg-gray-200 dark:bg-gray-700"></span>
                <span className="inline-flex h-4 w-20 rounded bg-gray-200 dark:bg-gray-700"></span>
              </div>
              <div className="w-40">
                <span className="inline-flex h-4 w-28 rounded bg-gray-200 dark:bg-gray-700"></span>
              </div>
              <div className="w-40">
                <span className="inline-flex h-4 w-20 rounded bg-gray-200 dark:bg-gray-700"></span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function FallbackTableRow({ column }: { column: number }) {
  return (
    <>
      {Array(6)
        .fill("fallback")
        .map((item, id) => {
          return (
            <tr className="" key={`${item}-${id}`}>
              {Array(column)
                .fill("col")
                .map((itemC, idC) => {
                  return (
                    <td key={`${itemC}-${idC}`}>
                      <span className="inline-flex h-4 w-10 rounded bg-gray-200 dark:bg-gray-700"></span>
                    </td>
                  );
                })}
            </tr>
          );
        })}
    </>
  );
}

export function FallbackSetupModule() {
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex items-center justify-between gap-x-4 px-4 py-3 shadow-sm">
        <div className="flex flex-col gap-x-4 gap-y-2">
          <span className="inline-flex h-5 w-36 rounded bg-gray-200 dark:bg-gray-700"></span>
          <div className="flex gap-x-4">
            <span className="inline-flex h-3 w-24 rounded bg-gray-200 dark:bg-gray-700"></span>
            <span className="inline-flex h-3 w-48 rounded bg-gray-200 dark:bg-gray-700"></span>
          </div>
        </div>
        <span className="ml-auto"></span>
        <span className="inline-flex h-4 w-44 rounded bg-gray-200 dark:bg-gray-700"></span>
        <span className="inline-flex h-4 w-20 rounded bg-gray-200 dark:bg-gray-700"></span>
      </div>

      <div className="flex w-full flex-col gap-y-8 py-8">
        <div className="flex flex-col gap-y-2 px-8">
          <span className="inline-flex h-4 w-20 rounded bg-gray-200 dark:bg-gray-700"></span>
          <span className="inline-flex h-3 w-52 rounded bg-gray-200 dark:bg-gray-700"></span>
          <div className="flex gap-x-4">
            <span className="inline-flex h-6 w-6 rounded bg-gray-200 dark:bg-gray-700"></span>
            <span className="inline-flex h-6 w-6 rounded bg-gray-200 dark:bg-gray-700"></span>
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-y-2 px-8">
          <span className="inline-flex h-4 w-20 rounded bg-gray-200 dark:bg-gray-700"></span>
          <span className="inline-flex h-3 w-52 rounded bg-gray-200 dark:bg-gray-700"></span>
        </div>
        <hr />
        <div className="flex flex-col gap-y-2 px-8">
          <span className="inline-flex h-4 w-20 rounded bg-gray-200 dark:bg-gray-700"></span>
          <span className="inline-flex h-3 w-52 rounded bg-gray-200 dark:bg-gray-700"></span>
          <div className="flex gap-x-4">
            <span className="inline-flex h-6 w-6 rounded bg-gray-200 dark:bg-gray-700"></span>
            <span className="inline-flex h-6 w-6 rounded bg-gray-200 dark:bg-gray-700"></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export function FallbackCard() {
  return (
    <div className="relative flex gap-x-6 rounded-lg border border-gray-200 dark:border-gray-700 p-4">
      <div className="w-10 flex-none">
        <span className="inline-flex h-10 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
      </div>
      <div className="flex w-full flex-wrap items-center gap-4">
        <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-36 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
      </div>
    </div>
  );
}

export function FallbackRow3Card() {
  return (
    <div className="flex flex-col gap-y-4 p-4">
      {Array(3)
        .fill("fallback")
        .map((_, e: number) => {
          return (
            <div
              key={e}
              className="relative flex gap-x-6 rounded-lg border border-gray-200 dark:border-gray-700 p-4"
            >
              <div className="w-10 flex-none">
                <span className="inline-flex h-10 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
              </div>
              <div className="flex w-full flex-wrap items-center gap-4">
                <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
                <span className="inline-flex h-4 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
                <span className="inline-flex h-4 w-36 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
                <span className="inline-flex h-4 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function FallbackSingle() {
  return (
    <div className="flex flex-col w-full gap-4 p-4">
      <span className="inline-flex h-4 w-32 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
      <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
      <span className="inline-flex h-4 w-40 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
    </div>
  );
}

export function FallbackCardWithImage() {
  return (
    <div className="relative flex w-full flex-col gap-x-6 gap-y-4 rounded-lg border border-gray-200 dark:border-gray-700 p-6 lg:w-2/12">
      <div className="flex w-full flex-wrap items-center gap-4">
        <span className="inline-flex h-4 w-36 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
      </div>
      <div className="flex w-full flex-wrap items-center gap-4">
        <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-8 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-8 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
      </div>
      <div className="flex h-full items-center justify-center">
        <span className="h-40 w-40 bg-gray-200 dark:bg-gray-700"></span>
      </div>
    </div>
  );
}

export function Fallback6Card() {
  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
      {Array(6)
        .fill("fallback")
        .map((item, id) => {
          return (
            <div
              className="relative flex gap-x-6 rounded-lg border border-gray-200 dark:border-gray-700 p-4"
              key={`${item}-${id}`}
            >
              <div className="w-10 flex-none">
                <span
                  className={[
                    "inline-flex h-10 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                  ].join(" ")}
                ></span>
              </div>
              <div className="flex w-full flex-wrap items-center gap-4">
                <span
                  className={[
                    "inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                  ].join(" ")}
                ></span>
                <span
                  className={[
                    "inline-flex h-4 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                  ].join(" ")}
                ></span>
                <span
                  className={[
                    "inline-flex h-4 w-36 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                  ].join(" ")}
                ></span>
                <span
                  className={[
                    "inline-flex h-4 w-10 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold",
                  ].join(" ")}
                ></span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function FallbackFormDetails() {
  return (
    <main className="flex w-screen flex-none flex-col items-start justify-center md:flex-auto">
      <div className="sticky top-0 z-30 flex w-full flex-col bg-white dark:bg-gray-900 py-2 shadow-sm px-6">
        <div className="flex flex-col gap-y-3">
          <span className="inline-flex h-4 w-72 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
          <div className="flex gap-x-4">
            <span className="inline-flex h-2 w-32 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
            <span className="inline-flex h-2 w-56 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
          </div>
        </div>
      </div>
      <div className="flex max-w-2xl flex-col gap-y-10 py-10 px-6">
        <div className="flex flex-col gap-y-3">
          <span className="inline-flex h-4 w-72 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
          <div className="flex gap-x-4">
            <span className="inline-flex h-2 w-32 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
            <span className="inline-flex h-2 w-56 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
          </div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex flex-col gap-y-3">
            <span className="inline-flex h-2 w-24 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
            <span className="inline-flex h-6 w-96 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
          </div>
          <div className="flex flex-col gap-y-3">
            <span className="inline-flex h-2 w-32 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
            <span className="inline-flex h-6 w-52 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
          </div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex flex-col gap-y-3">
            <span className="inline-flex h-2 w-32 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
            <span className="inline-flex h-6 w-52 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
          </div>
          <div className="flex flex-col gap-y-3">
            <span className="inline-flex h-2 w-32 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
            <span className="inline-flex h-6 w-52 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
          </div>
          <div className="flex flex-col gap-y-3">
            <span className="inline-flex h-2 w-32 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
            <span className="inline-flex h-6 w-52 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1"></span>
          </div>
        </div>
      </div>
    </main>
  );
}

export function FallbackDetailsErm() {
  return (
    <div className="flex h-96 w-screen flex-none flex-col md:flex-auto">
      <div className="flex gap-x-4 px-6 pt-4">
        <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-6 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-28 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-6 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-40 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-6 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
      </div>
      <div className="mb-4 flex gap-x-4 px-6 pt-4">
        <span className="inline-flex h-4 w-40 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-16 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
        <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
      </div>
      <hr className="border-gray-200 dark:border-gray-700" />
      <div className="flex">
        <div className="hidden min-h-screen w-2/12 flex-col flex-wrap items-start border-r border-gray-200 dark:border-gray-700 p-4 sm:flex">
          <div className="mb-4 flex flex-wrap gap-x-4 gap-y-2 px-6 pt-4">
            <span className="inline-flex h-4 w-40 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-16 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-24 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
          </div>
          <div className="mb-4 flex flex-wrap gap-x-4 gap-y-2 px-6 pt-4">
            <span className="inline-flex h-4 w-36 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-56 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
          </div>
          <div className="mb-4 flex flex-wrap gap-x-4 gap-y-2 px-6 pt-4">
            <span className="inline-flex h-4 w-16 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
          </div>
        </div>
        <div className="w-full px-6 py-4 sm:w-6/12">
          <div className="mb-4 flex gap-x-4 px-6 pt-4">
            <span className="inline-flex h-4 w-40 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-16 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
          </div>
          <div className="mb-4 flex gap-x-4 px-6 pt-4">
            <span className="inline-flex h-4 w-40 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-16 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
          </div>
          <div className="mb-4 flex gap-x-4 px-6 pt-4">
            <span className="inline-flex h-4 w-36 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-56 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
          </div>
          <div className="mb-4 flex gap-x-4 px-6 pt-4">
            <span className="inline-flex h-4 w-16 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
            <span className="inline-flex h-4 w-20 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
