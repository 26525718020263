import { useAppSelector } from "lib/store/hooks";
import Button from "react-components/dist/components/Button";
import modal from "react-components/dist/components/Modal";
import QRCode from "react-qr-code";

function ModalQrCodeRegisterPatient() {
  const { selected } = useAppSelector((state) => state.clinics);

  if (!selected || selected?.slug === "")
    return (
      <div className="text-gray-600 dark:text-gray-400">
        Belum melakukan pengaturan `slug` pada halaman [ pengaturan {">"}{" "}
        Halaman Publik ]
      </div>
    );

  const link = `${process.env.REACT_APP_DOCUMENT_URL}/clinics/${selected.slug}/register-patient`;

  return (
    <div className="flex flex-col gap-y-4">
      <div className="bg-white p-6 rounded-lg inline-flex mx-auto">
        <QRCode value={link} id="qrcode-link-pendaftaran" />
      </div>
      <span className="text-center text-sm text-gray-600 dark:text-gray-400">
        Silakan minta pasien untuk melakukan pendaftaran mandiri melalui
        smartphonenya dengan cara scan qrcode di atas
      </span>

      <div className="z-20 flex justify-between w-full gap-x-4 bottom-0 sticky">
        <Button className="px-4" state="tertiary" color="stale">
          <button type="button" onClick={modal.hide}>
            Tutup
          </button>
        </Button>
        <Button className="px-4" ripple state="primary">
          <a href={link} target="_blank" rel="noopener noreferrer" className="">
            Buka Tautan
          </a>
        </Button>
      </div>
    </div>
  );
}

export default ModalQrCodeRegisterPatient;
