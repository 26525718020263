import { createResource } from "lib/helpers/createResource";
import { Suspense, useEffect, useState } from "react";

import dashboard from "lib/constants/api/dashboard";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/molecules/ErrorFallback";
import { PencilSimple } from "@phosphor-icons/react";
import Button from "react-components/dist/components/Button";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

function BannerLogin({ resource }: { resource: any }) {
  const response = resource.read();

  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{
        clickable: true,
        bulletClass:
          "swiper-pagination-bullet !w-5 !h-5 !bg-yellow-700 opacity-60",
        bulletActiveClass: "!opacity-100",
      }}
      className="!h-full"
      autoplay={{
        delay: 7000,
      }}
    >
      {response?.map?.((item: any) => {
        return (
          <SwiperSlide
            key={item.id}
            className="!h-full w-full rounded-3xl overflow-hidden relative"
          >
            {process.env.REACT_APP_STAGING === "dev" && (
              <Button
                state="secondary"
                className="!absolute top-10 right-10 z-20 px-2"
              >
                <a
                  href={`https://ghost.mejadokter.com/ghost/#/editor/post/${item.id}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <PencilSimple size={22} />
                </a>
              </Button>
            )}
            <img
              src={item.feature_image}
              alt={item.slug}
              className="object-cover object-center absolute inset-0 z-10 w-full h-full"
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

function Banner() {
  const [resource, setResource] = useState(null);

  useEffect(() => {
    setResource(
      createResource(dashboard.getInternalBannerLogin({ page: 1, limit: 6 }))
    );
  }, []);

  return (
    <div className="w-6/12 h-screen p-10 hidden lg:block">
      <ErrorBoundary fallbackRender={(props) => <ErrorFallback {...props} />}>
        <Suspense
          fallback={
            <span className="inline-flex h-4 w-36 rounded-sm bg-gray-200 dark:bg-gray-700 px-2 py-1 text-xs font-bold"></span>
          }
        >
          {resource && <BannerLogin resource={resource} />}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default Banner;
