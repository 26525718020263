import { createSlice } from '@reduxjs/toolkit';
import { AvailablePaymentMethod } from 'lib/constants/paymentMethods';

type TIks = {
  _id: string;
  fullName: string;
  isGeneral: boolean;
  isBPJS: boolean;
  consultationPrice?: any;
  paymentMethod?: AvailablePaymentMethod[];
  displayAs?: string;
};

export type TAccountings = {
  hasFinishOnboarding: boolean;
};

export type TPatientPager = {
  name: string;
  code: string;
  template: string;
  youtubeURL: string;
  youtubeVideoURL: string;
  youtubeListURL: string;
  _id?: string;
};

export type TClinicSettings = {
  pharmacies?: {
    name: string;
    phone: string;
    address: string;
    logo: string;
  };
  accountings?: TAccountings;
  vitalSign?: {
    suhu: number;
    sistole: number;
    diastole: number;
    respiratoryRate: number;
    heartRate: number;
    oxygenSaturation: number;
  };
  patientPagers?: TPatientPager[];
};

type TTypeFaskes =
  | 'klinik'
  | 'rumah-sakit'
  | 'praktek-mandiri'
  | 'praktek-mandiri-nurse'
  | 'puskesmas'
  | 'klinik-hewan';

type TStaff = {
  _id: string;
  type: string;
  idUser: string;
  fullName: string;
  ijinPraktek: string;
  isBidan: boolean;
  isApoteker: boolean;
  phoneNumber?: string;
  metaData: {
    signature: string;
    [key: string]: any;
  };
};

export type Clinic = {
  _id: string;
  name: string;
  logo: string;
  balanceAmount: number;
  hasRanap: boolean;
  metaData: {
    authorizationChangePrice: 'general' | 'managers-approval' | 'block';
    formatNomorRekamMedis?: any;
    codingRM?: any;
    [key: string]: any;
  };
  typeFaskes: TTypeFaskes;
  iks: TIks[];
  nurse: TStaff[];
  doctor: TStaff[];
  settings: TClinicSettings;
  slug?: string;
  [key: string]: any;
};

export interface IClinicState {
  available: Clinic[];
  selected: Clinic | null;
}

const initialState: IClinicState = {
  available: [],
  selected: null,
};

export const clinicsSlice = createSlice({
  name: 'clinics',
  initialState,
  reducers: {
    setAvailableClinic: (state, action) => {
      state.available = action.payload;
    },
    setBalance: (state, action) => {
      if (state.selected) state.selected.balanceAmount = action.payload;
    },
    selectClinic: (state, action) => {
      state.selected = action.payload;
    },
    updateClinicMetaData: (state, action) => {
      if (state.selected) {
        state.selected.metaData[action.payload.key] = action.payload.value;
      }
    },
    updateClinicMetaDataLandingPage: (state, action) => {
      if (state.selected) {
        state.selected.metaDataLandingPage = action.payload;
      }
    },
    updateClinicMetaDataCrm: (state, action) => {
      if (state.selected) {
        state.selected.metaDataCrm = action.payload;
      }
    },
    updateClinicMetaDataSatuSehat: (state, action) => {
      if (state.selected) {
        state.selected.metaData.satusehat = action.payload;
      }
    },
    updateSettings: (state, action) => {
      if (state.selected) {
        state.selected.settings = action.payload;
      }
    },
  },
});

export const {
  setAvailableClinic,
  selectClinic,
  updateClinicMetaData,
  updateClinicMetaDataLandingPage,
  updateClinicMetaDataSatuSehat,
  updateClinicMetaDataCrm,
  setBalance,
  updateSettings,
} = clinicsSlice.actions;

export default clinicsSlice.reducer;
