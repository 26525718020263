import axios from "lib/fetch/axios";
import ghost from "lib/fetch/ghost";

import getSelectedIdClinic from "lib/helpers/getSelectedIdClinic";

function getNewDashboard() {
  return axios({
    method: "get",
    url: `${
      process.env.REACT_APP_AUTH_URL
    }/api/v2/clinics/${getSelectedIdClinic()}/dashboard/new`,
  });
}

interface paramsInternalTutorial {
  page?: number;
  limit?: number;
  include?: any;
}
function getInternalTutorial({
  page = 1,
  limit = 3,
  include = ["tags", "authors"],
}: paramsInternalTutorial) {
  return ghost.posts.browse({
    page,
    limit,
    include,
    filter: "tag:internals+tag:tutorials+tag:mejadokter",
  });
}

function getDetailInternalTutorial(slug: string) {
  return ghost.posts.read(
    { slug },
    {
      formats: ["html", "plaintext"],
      include: "tags",
      filter: "tag:internals+tag:tutorials+tag:mejadokter",
    }
  );
}

function getArticleBySlug({ slug }: { slug: string }) {
  return ghost.posts.read(
    { slug },
    {
      formats: ["html", "plaintext"],
      include: "tags",
      filter: "tag:internals+tag:changelogs+tag:mejadokter",
    }
  );
}

type paramsBannerLogin = {
  page?: number;
  limit?: number;
};
function getInternalBannerLogin({ page = 1, limit = 6 }: paramsBannerLogin) {
  return ghost.posts.browse({
    page,
    limit,
    order: "published_at DESC",
    filter: "tag:internals+tag:banner-login+tag:mejadokter",
  });
}

const exported = {
  getNewDashboard,
  getInternalTutorial,
  getArticleBySlug,
  getDetailInternalTutorial,
  getInternalBannerLogin,
};

export default exported;
