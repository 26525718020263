import { createSlice } from "@reduxjs/toolkit";

import {
  format,
  addDays,
  subDays,
  subWeeks,
  addWeeks,
  subMonths,
  addMonths,
} from "date-fns";
import { TDeposit } from "lib/constants/api/calendar";

export type TTab = "day" | "week" | "month" | "schedule";

export type TData = {
  _id: string;
  channel: "manual" | "booking-system" | "planning" | "landing-page";
  createdAt: string;
  updatedAt: string;
  dateStart: string;
  description: string;
  idClinic: string;
  idRecipient?: string;
  metaData?: {
    deposit?: TDeposit;
    patient?: {
      fullName: string;
      _id: string;
      gender: string;
      [key: string]: string;
    };
  };
  patient: {
    fullName: string;
    _id: string;
    gender: string;
    [key: string]: string;
  };
  statusBooking: "pending" | "in-progress" | "cancelled" | "done";
  type: "rekam-medis" | "laboratorium";
};

export interface ICalendar {
  count: number;
  isCalendarLoading: boolean;
  viewMode?: "day" | "week" | "month" | "schedule";
  dateStart: string;
  dateEnd: string;
  data: TData[];
}

const initialState: ICalendar = {
  count: 0,
  isCalendarLoading: false,
  viewMode: undefined,
  dateStart: format(new Date(), "yyyy-MM-dd"),
  dateEnd: format(new Date(), "yyyy-MM-dd"),
  data: [],
};

export const slice = createSlice({
  name: "calendars",
  initialState,
  reducers: {
    updateCalendarState: (state, action) => {
      state.count = action.payload.count;
    },
    resetCalendarState: (state) => {
      state.count = 0;
    },
    initCalendar: (
      state,
      action: {
        payload: {
          dateStart: string;
          viewMode: TTab;
        };
      }
    ) => {
      state.dateStart = action.payload.dateStart;
      state.viewMode = action.payload.viewMode;
    },
    changeViewMode: (
      state,
      action: {
        payload: TTab;
      }
    ) => {
      state.viewMode = action.payload;
    },
    navigateDate: (
      state,
      action: {
        payload: {
          viewMode: TTab;
          dateStart: string;
          step: "previous" | "next";
          setSearchParams: Function;
        };
      }
    ) => {
      let currentDate;

      if (action.payload.viewMode === "day") {
        if (action.payload.step === "previous") {
          currentDate = subDays(new Date(state.dateStart), 1);
        } else if (action.payload.step === "next") {
          currentDate = addDays(new Date(state.dateStart), 1);
        }
      } else if (action.payload.viewMode === "week") {
        if (action.payload.step === "previous") {
          currentDate = subWeeks(new Date(state.dateStart), 1);
        } else if (action.payload.step === "next") {
          currentDate = addWeeks(new Date(state.dateStart), 1);
        }
      } else if (action.payload.viewMode === "month") {
        if (action.payload.step === "previous") {
          currentDate = subMonths(new Date(state.dateStart), 1);
        } else if (action.payload.step === "next") {
          currentDate = addMonths(new Date(state.dateStart), 1);
        }
      }

      if (currentDate) {
        state.dateStart = format(currentDate, "yyyy-MM-dd");

        action.payload.setSearchParams({
          dateStart: format(currentDate, "yyyy-MM-dd"),
          viewMode: action.payload.viewMode,
        });
      }
    },
    dateStartSet: (
      state,
      action: {
        payload: string;
      }
    ) => {
      state.dateStart = action.payload;
    },
    dateEndSet: (state, action) => {
      state.dateEnd = action.payload;
    },
    loadingSet: (
      state,
      action: {
        payload: boolean;
      }
    ) => {
      state.isCalendarLoading = action.payload;
    },
    dataSet: (
      state,
      action: {
        payload: TData[];
      }
    ) => {
      state.data = action.payload;
    },
  },
});

export const {
  updateCalendarState,
  resetCalendarState,
  initCalendar,
  changeViewMode,
  navigateDate,
  dateStartSet,
  dateEndSet,
  loadingSet,
  dataSet,
} = slice.actions;

export default slice.reducer;
