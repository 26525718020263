import { Suspense, useEffect, useState } from 'react';
import APIPatientPager from 'lib/constants/api/patientPagers';
import { createResource } from 'lib/helpers/createResource';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/molecules/ErrorFallback';
import { Fallback6Card } from 'components/molecules/FallbackComponent';
import ListPatientPagers from './ListPatientPagers';
import { useAppSelector } from 'lib/store/hooks';
import Button from 'react-components/dist/components/Button';
import { Link } from 'react-router-dom';
import modal from 'react-components/dist/components/Modal';
import { Gear } from '@phosphor-icons/react';

function PatientPager() {
  const users = useAppSelector((state) => state.users);
  const { selected } = useAppSelector((state) => state.clinics);

  const [resource, resourceSet] = useState(null);

  function reload() {
    resourceSet(createResource(APIPatientPager.list()));
  }

  useEffect(() => {
    reload();
  }, []);

  return (
    <div className="flex flex-col gap-y-2">
      <span className="flex flex-col gap-y-1 px-4">
        <h6 className="text-sm text-gray-900 dark:text-gray-200">Pemanggil Pasien</h6>
      </span>
      <div className="w-full px-4 grid grid-cols-4 gap-2">
        <ErrorBoundary
          fallbackRender={(props) => (
            <div className="flex h-96 flex-none items-center justify-center md:flex-auto">
              <ErrorFallback {...props} />
            </div>
          )}
        >
          <Suspense fallback={<Fallback6Card />}>
            {resource && <ListPatientPagers resource={resource} reload={reload} />}
          </Suspense>
        </ErrorBoundary>
        {(users.profile?.entity === 'owner' || !!users.profile?.authority?.settings?.['kiosk']) && (
          <div className="relative flex rounded border border-gray-200 aspect-square dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 p-4 w-full items-center justify-center transition-all duration-300">
            <span className="text-gray-900 dark:text-gray-200">Kelola Pemanggil</span>
            <Button className="!p-3 !absolute top-4 right-4" ripple state="secondary">
              <Link
                to={`/clinics/${selected?._id}/settings/kiosk`}
                onClick={() => {
                  modal.hide();
                }}
              >
                <Gear size={22} />
              </Link>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PatientPager;
