import PatientPager from './PatientPager';

function ModalLauncher() {
  return (
    <div className="flex flex-col gap-y-4 py-4">
      <span className="flex flex-col gap-y-1 px-4">
        <h6 className="text-sm text-gray-900 dark:text-gray-200">Launcher</h6>
        <span className="text-sm text-gray-600 dark:text-gray-400">
          Luncurkan jendela pemanggil antrian, KIOSK, dan halaman publik lainnya
        </span>
      </span>
      <hr />
      <PatientPager />
    </div>
  );
}

export default ModalLauncher;
